
import { defineComponent, ref } from "vue";
import {
  CalendarOptions,
  EventApi,
  DateSelectArg,
  EventClickArg,
} from "@fullcalendar/core";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import idLocale from "@fullcalendar/core/locales/id";
import { INITIAL_EVENTS, createEventId } from "@/core/helpers/event-utils";
import { EventList } from "@/core/types/gws-ap2tki/schedule/EventList";
import { start } from "@popperjs/core";

export default defineComponent({
  name: "ScheduleCalendar",
  props: {
    eventData: {
      type: Array,
      default: [] as EventList[],
    },
    startDate: {
      type: String,
      default: ''
    },
    endDate: {
      type: String,
      default: '',
    }
  },
  emits: ["event-click"],
  components: {
    FullCalendar,
  },
  setup(props, { emit }) {
    const handleEditCourse = (e) => {
      emit("event-click", e);
    };
    return {
      handleEditCourse,
    };
  },
  data() {
    return {
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: "",
        },
        initialView: "dayGridMonth",
        editable: false,
        allDaySlot: false,
        locale: idLocale,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        eventTextColor: '#FFF',
        dayHeaderFormat: {
          weekday: "long",
        },
        eventChange: this.handleRefetch,
        weekends: true,
        eventClick: this.handleEventClick,
        events: this.eventData,
        visibleRange: {
          start: this.startDate,
          end: this.endDate,
        },
      } as CalendarOptions,
    };
  },
  methods: {
    handleEventClick(clickInfo: EventClickArg) {
      this.handleEditCourse(clickInfo.event);
    },
    handleRefetch() {
      (this.$refs.eventCal as any).handleRefetch();
    },
  },
});
