import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, isRef as _isRef, withCtx as _withCtx, createBlock as _createBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-80e88dfe")
const _hoisted_1 = { class: "cont-siswa" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "d-flex gap-4 p-2 align-items-center" }
const _hoisted_4 = { class: "avatar" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "w-100" }
_popScopeId()

import { PropType, computed, toRef } from "vue";
import { useBlkServiceFormStore } from "@/store/pinia/gws-blk/service-application/useBlkServiceFormStore";
import { useGlobalLookupStore } from "@/store/pinia/application/useGlobalLookupStore";
import { SiswaItem } from "@/core/types/gws-blk/MonitoringListDetail";

export default _defineComponent({
  props: {
  visibility: {
    type: Boolean,
    default: false,
  },
  listSiswa: {
    type: Array as PropType<SiswaItem[]>,
    default: [] as SiswaItem[],
  },
},
  emits: ["handle-close", "handle-approve"],
  setup(__props, { emit: emits }) {

const props = __props
const formStore = useBlkServiceFormStore();
const lookupStore = useGlobalLookupStore();



//computed
const formData = computed(() => {
  return formStore.formData;
});
const classData = computed(() => {
  return lookupStore.serviceGroup;
});
//refs
const isVisible = toRef(props, "visibility");

//handlers
const handleCloseModal = (isReload = false) => {
  emits("handle-close", isReload);
};

return (_ctx: any,_cache: any) => {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(isVisible),
    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(isVisible) ? isVisible.value = $event : null)),
    title: "Daftar Siswa",
    width: 500,
    "close-on-click-modal": false,
    "show-close": true,
    onClose: _cache[2] || (_cache[2] = ($event: any) => (handleCloseModal()))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("button", {
        class: "btn btn-primary w-100",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (handleCloseModal()))
      }, " Tutup ")
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.listSiswa, (dt, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "row"
          }, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("img", {
                    class: "w-100",
                    alt: "Logo",
                    src: dt.photo
                  }, null, 8, _hoisted_5)
                ]),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("h5", null, _toDisplayString(dt.nama), 1)
                ])
              ])
            ])
          ]))
        }), 128))
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})