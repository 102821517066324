import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, isRef as _isRef } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "d-flex justify-content-between align-items-center" }

import { PropType, computed, toRef } from "vue";
import { useBlkMonitoringFormStore } from "@/store/pinia/gws-blk/service-monitoring/useBlkMonitoringFormStore";
import { useGlobalLookupStore } from "@/store/pinia/application/useGlobalLookupStore";
import { SiswaItem } from "@/core/types/gws-blk/MonitoringListDetail";

export default _defineComponent({
  props: {
  visibility: {
    type: Boolean,
    default: false,
  },
},
  emits: ["handle-close", "handle-save"],
  setup(__props, { emit: emits }) {

const props = __props
const formStore = useBlkMonitoringFormStore();
const lookupStore = useGlobalLookupStore();



//computed
const formData = computed(() => {
  return formStore.formData;
});
const participant = computed(() => {
  return lookupStore.groupParticipant;
});
//refs
const isVisible = toRef(props, "visibility");

//handlers
const handleCloseModal = (isReload = false) => {
  emits("handle-close", isReload);
};
const handleSave = () => {
  emits("handle-save");
};

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_checkbox_group = _resolveComponent("el-checkbox-group")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    modelValue: _unref(isVisible),
    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_isRef(isVisible) ? isVisible.value = $event : null)),
    title: "Daftar Siswa",
    width: 500,
    "close-on-click-modal": false,
    "show-close": true,
    onClose: _cache[4] || (_cache[4] = ($event: any) => (handleCloseModal()))
  }, {
    footer: _withCtx(() => [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: "btn btn-danger ",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (handleCloseModal()))
        }, " Tutup "),
        _createElementVNode("button", {
          class: "btn btn-primary ",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (handleSave()))
        }, " Simpan ")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_checkbox_group, {
            modelValue: _unref(formStore).stateSelectedCpmi,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_unref(formStore).stateSelectedCpmi = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(participant), (cpmi, i) => {
                return (_openBlock(), _createBlock(_component_el_checkbox, {
                  key: i,
                  label: cpmi.value,
                  class: "w-100 p-2 m-1 border rounded"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(cpmi.label), 1)
                  ]),
                  _: 2
                }, 1032, ["label"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["modelValue"]))
}
}

})